<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn" @click="goBack">
				<i class="icon icon icon-left">{{t('10030')}}</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">{{t('10764')}} </span>
			</p>
			<a class="nav__btn" v-if="mrm01011.length !== 0" @click="handleOpenMapRooms">
				<i class="icon icon-map"></i>
			</a>
			<a class="nav__btn" v-else >
			</a>
		</div>
	</nav>
	<!-- //nav-->
    <div id="container" class="container container--fit">
        <div id="sub" class="sub quicksearch">
	        <!-- search-form -->
	        <section class="search-form">
		        <div class="box">
			        <div class="search-form__wrap">
				        <input type="text" class="ipt ipt--muted" ref="searchBox" v-model="searchTextInput" @keyup.enter="$refs.searchBox.blur();onClickSearch();">
				        <button type="submit" class="btn-search" @click="onClickSearch">
					        <i class="icon icon-search"></i>
				        </button>
			        </div>
		        </div>
	        </section>
            <!-- category -->
            <section class="listsearch">
                <div class="listsearch__wrap">
                    <div class="listsearch__inner">
                        <router-link to="/layers/date" custom v-slot="{ navigate }" >
                            <div class="listsearch__box" @click="navigate">
                                <span class="listsearch__icon">
                                    <i class="icon icon-date"></i>
                                </span>
                                <input v-if="dateRangeText !== null" type="text" class="listsearch__ipt" :value="dateRangeText" readonly> 
                            </div>
                        </router-link>
                    </div>
                    <div class="listsearch__inner">
                        <router-link to="/layers/member" custom v-slot="{ navigate }" >
                            <div class="listsearch__box" @click="navigate">
                                <span class="listsearch__icon">
                                    <i class="icon icon-member"></i>
                                </span>
                                <input v-if="selectedMember !== null" type="text" class="listsearch__ipt" :value="`성인 ${selectedMember.adultCount}명, 아동 ${selectedMember.childCount}명`" readonly>
                            </div>
                        </router-link>
                    </div>
                </div>
            </section>
            <!-- content -->
            <section class="content">
                <div class="box">
                    <div class="menus">
                        <div class="menus__wrap">
                            <!-- [D] 활성화된 지역에 active 클래스 추가 -->
                            <!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
							<div class="menus__box">
								<div class="menus__text" :class="{active: hotelType === 'MO'}" @click="setHotelType('MO')">{{t('10021')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'HO'}" @click="setHotelType('HO')">{{t('10022')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'PS'}" @click="setHotelType('PS')">{{t('10023')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'RT'}" @click="setHotelType('RT')">{{t('10024')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'CP'}" @click="setHotelType('CP')">{{t('10025')}}</div>
								<div class="menus__text" :class="{active: hotelType === 'GH'}" @click="setHotelType('GH')">{{t('10029')}}</div>
							</div>
                        </div>
                    </div>
                </div>
                <div class="listbox">
                    <div class="box">
                        <div class="list__meta">
                            <div class="list__check">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" class="chk blind" v-model="cp_yn">
                                        <span class="checkbox__bg"></span>
                                        {{t('10008')}}
                                    </label>
                                </div>
                            </div>
                            <div class="list__option">
                                <button type="button" @click="handleOpenSorting">
                                    <i class="icon icon-align">
                                        {{t('10009')}}
                                    </i>
                                </button>
                                <button type="button" @click="handleOpenFilter">
                                    <i class="icon icon-filter">
                                        {{t('10017')}}
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- [REST] 숙소 리스트 -->
                    <rooms-thumbnail-list v-if="hotelType === 'HO'" :list="mrm01011" :sortMode="sortMode"/>
					<RoomsList v-else :list="mrm01011" :sortMode="sortMode"/>
                </div>
            </section>
            <section class="content" v-if="mrm01011.length === 0">
                <div class="quicksearch__noresult">
                    <div class="textbox">
                        <div class="textbox__icon">
                            <img src="@/assets/images/img_warning.png" alt="찾으시는 결과가 없나요?" style="width:64px">
                        </div>
                        <h3 class="textbox__title">{{t('10576')}}</h3>
                    </div>
                </div>
                <!-- recommendation -->
                <div class="row">
                    <!-- style -->
                    <div class="style">
                        <div class="style__item">
                            <h3 class="style__title">
                                {{t('10577')}}
                            </h3>
                            <ul class="style__list" v-if="'03' in mmy01421">
                                <li v-for="item in mmy01421['03']" :key="item.SEQ" @click="onClickSearchItem(item.SEARCH_WORD)">
                                    <label>
                                        <a class="style__text">
                                            {{item.SEARCH_WORD}}
                                        </a>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <!-- //content -->
        </div>
    </div>
    <TheNavigation></TheNavigation>
	<RoomSorting v-if="openSorting" :handleClose="handleCloseSorting" :mode="sortMode" :changeMode="changeSortMode"/>
	<RoomFilter v-if="openFilter" :handleClose="handleCloseFilter" :handleSubmit="handleSubmitFilter"/>
	<MapRooms v-if="openMapRooms" :handleClose="handleCloseMapRooms" :list="mrm01011" />
  <TheTop/>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref, reactive } from "vue"
import { useRoute, useRouter } from 'vue-router';
import RoomsList from '@/components/RoomsList';
import RoomsThumbnailList from '@/components/RoomsThumbnailList';
import dayjs from "dayjs";
import "dayjs/locale/ko";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

dayjs.locale("ko");
export default {
	components: {
		RoomsList,
        RoomsThumbnailList
	},
    setup() {
        const store = useStore();
        const router = useRouter();
		const route = useRoute();

        const userData = computed(() => store.state.userData);
        // const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const mrm01011 = computed(() => store.state.mrm01.mrm01011);
        const mmy01421 = computed(() => store.state.mmy01.mmy01421);

        const searchText = ref(route.query.search ?? "");
        const searchTextInput = ref(route.query.search ?? "");
		const hotelType = ref("MO");
		const cp_yn = ref(false);
		const openSorting = ref(false);
		const openFilter = ref(false);
		const openMapRooms = ref(false);
		const watchCall = ref(false);
        const sortMode = ref(0);

		let filterData = reactive({convs:undefined,price:[undefined, undefined]});

        const goBack = () => {
            router.go(-1);
        };

        const onClickSearch = () =>{
            store.commit("search/ADD_SEARCH_ITEM", {searchItem: searchTextInput.value});
            searchText.value = searchTextInput.value;
            router.replace({
                path: "/search/rooms",
                query: {
                    search: searchTextInput.value
                }
            });
        };

        const onClickSearchItem = (searchWord) =>{
            store.commit("search/ADD_SEARCH_ITEM", {searchItem: searchWord});
            searchText.value = searchWord;
            searchTextInput.value = searchWord;
        };

        onMounted(()=>{
            store.dispatch("mmy01/fetchMmy01421",{
                proc_cd: "03",
            });
            if(store.state.mhm01.selectedDateRange === null) {
                store.commit('mhm01/SET_SELECT_DATE', {dateRange: {start: dayjs().toISOString(), end: dayjs().add(1, "day").toISOString()}});
            }
            if(store.state.mhm01.selectedMember === null) {
                store.commit('mhm01/SET_MEMBER', {member: {adultCount: 2, childCount: 0}});
            }
        });


		store.watch(() => [
            watchCall.value,
			store.state.mhm01.selectedDateRange,
			store.state.mhm01.selectedMember,
			hotelType.value,
			cp_yn.value,
            searchText.value,
            filterData,
		], ([watchCall, selectedDateRange, selectedMember, hotel_type, cp_yn, searchText, filterData]) => {
			console.log(watchCall, selectedDateRange, selectedMember, hotel_type, cp_yn, searchText, filterData);
			if(selectedDateRange !== null && selectedMember !== null /*&& searchText !== ""*/) {
				store.dispatch("mrm01/fetchMrm01011", {
					sdate: dayjs(selectedDateRange.start).format("YYYYMMDD"),
					edate: dayjs(selectedDateRange.end).format("YYYYMMDD"),
					hotel_types: hotel_type,
					adult_cnt: selectedMember.adultCount,
					child_cnt: selectedMember.childCount,
					room_cnt: 1,
					cp_yn: cp_yn ? "Y" : undefined,
					mem_id: userData.value.mem_token,
                    search_words: searchText.split(" ").join(","),
                    hotel_convs: filterData.convs === undefined ? undefined : filterData.convs.join(","),
                    samt: filterData.price[0] !== undefined ? Number(filterData.price[0]) * 10000 : undefined,
                    eamt: filterData.price[1] !== undefined ? filterData.price[1] > 50 ? undefined : Number(filterData.price[1]) * 10000 : undefined,
				})
			}
		}, {immediate: true})
        const { t }= useI18n() //번역필수 모듈

        return {
            dateRangeText: computed(() =>
                store.state.mhm01.selectedDateRange !== null
                ? dayjs(store.state.mhm01.selectedDateRange.start).format(
                    "MM.DD(dd)"
                    ) +
                    "~" +
                    dayjs(store.state.mhm01.selectedDateRange.end).format("MM.DD(dd)")
                : null
            ),
            selectedMember: computed(() => store.state.mhm01.selectedMember),
            goBack,
            searchText,
			setHotelType: (hotel_type) => {
				hotelType.value = hotel_type;
			},
			openSorting,
			handleOpenSorting: () => {
				openSorting.value = true;
			},
			handleCloseSorting: () => {
				openSorting.value = false;
			},
            sortMode,
            changeSortMode:(m) => {
				sortMode.value = m;
			},
			openFilter,
			handleOpenFilter: () => {
				openFilter.value = true;
			},
			handleCloseFilter: () => {
				openFilter.value = false;
			},
            handleSubmitFilter: (convs, price) => {
				openFilter.value = false;

                filterData = reactive({
                    convs,
                    price,
                });
                watchCall.value = !watchCall.value;
			},
			openMapRooms,
			handleOpenMapRooms: () => {
				openMapRooms.value = true;
			},
			handleCloseMapRooms: () => {
				openMapRooms.value = false;
			},
            hotelType,
            onClickSearch,
            onClickSearchItem,
            cp_yn,
            mrm01011,
            searchTextInput,
            mmy01421,
            t,
            i18n
        }
    },
}
</script>